import { Snackbar, TextField } from '@material-ui/core';
import { blueGrey, blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

export const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      color: blueGrey[700],
      '&:hover': {
        backgroundColor: blueGrey[50],
      },
    },
    '& .MuiInputLabel-outlined': {
      color: blueGrey[500],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: blueGrey[200],
      },
      '&:hover fieldset': {
        borderColor: blueGrey[500],
      },
      '&.Mui-focused fieldset': {
        borderColor: blueGrey[500],
      },
    },
  },
})(TextField);

export const StyledSuccessSnackbar = withStyles({
  root: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: blue[500],
    },
  },
})(Snackbar);
