import * as firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Category, Form } from '../../types';
import { sleep } from '../../effects/sleep';
import * as api from '../../effects/api';
import CategoryForm from './CategoryForm';
import DateForm from './DateForm';
import DescriptionForm from './DescriptionFrom';
import ItemNameForm from './ItemNameForm';
import PetrolForm from './PetrolForm';
import PriceForm from './PriceForm';
import FormSnackbar from './FormSnackbar';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiPickersSlideTransition-transitionContainer.MuiPickersCalendarHeader-transitionContainer': {
      color: blueGrey[500],
    },
  },
  forms: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(2),
  },
}));

type State = {
  category: Category | null;
  itemName: string;
  date: Date;
  price: string;
  description: string;
  priceError: string | null;
  apiError: string | null;
  isPosting: boolean;
  showSuccess: boolean;
};

type Action =
  | {
      type: 'onChangeCategory';
      payload: Category;
    }
  | {
      type: 'onChangeItemName';
      payload: string;
    }
  | {
      type: 'onChangeDate';
      payload: Date;
    }
  | {
      type: 'onChangePrice';
      payload: string;
    }
  | {
      type: 'onChangeDescription';
      payload: string;
    }
  | { type: 'onStartPosting' }
  | { type: 'onSuccessPosting' }
  | { type: 'onDelayedSuccessPosting' }
  | { type: 'onFailurePosting'; payload: string };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'onChangeCategory':
      return {
        ...state,
        category: action.payload,
      };
    case 'onChangeItemName':
      return {
        ...state,
        itemName: action.payload,
        price: state.price,
      };
    case 'onChangeDate':
      return {
        ...state,
        date: action.payload,
      };
    case 'onChangePrice':
      return {
        ...state,
        price: action.payload,
        priceError: Number.isNaN(Number(action.payload)) ? 'invalid number' : null,
      };
    case 'onChangeDescription':
      return {
        ...state,
        description: action.payload,
      };
    case 'onStartPosting':
      return {
        ...state,
        apiError: null,
        isPosting: true,
      };
    case 'onSuccessPosting':
      return {
        ...initialState,
        date: state.date,
        isPosting: false,
        showSuccess: true,
      };
    case 'onDelayedSuccessPosting':
      return {
        ...state,
        showSuccess: false,
      };
    case 'onFailurePosting':
      const message = JSON.stringify(action.payload);
      return {
        ...state,
        isPosting: false,
        apiError: `Report Error to Developer, ${message}`,
      };
    default:
      return state;
  }
};

const initialState: State = {
  category: null,
  itemName: '',
  date: new Date(),
  price: '',
  description: '',
  priceError: null,
  apiError: null,
  isPosting: false,
  showSuccess: false,
};

const Forms: React.FC<{}> = () => {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    api.warmUp();
  }, []);

  const onSubmit = React.useCallback(async () => {
    dispatch({ type: 'onStartPosting' });
    const form: Form = {
      date: state.date.toISOString().split('T')[0],
      itemName: state.itemName,
      category: state.category,
      price: Number(state.price),
      description: state.description,
    };

    try {
      const u = firebase.auth().currentUser;
      if (u === null) {
        firebase.auth().signOut();
        return;
      }
      const idToken = await u.getIdToken(true);

      await api.insert(form, idToken);
      dispatch({ type: 'onSuccessPosting' });
      await sleep(2000);
      dispatch({ type: 'onDelayedSuccessPosting' });
    } catch (err) {
      const message: string = err instanceof Error ? JSON.stringify(err) : 'unknown';
      dispatch({
        type: 'onFailurePosting',
        payload: message,
      });
    }
  }, [state]);

  return (
    <div className={classes.forms}>
      <DateForm date={state.date} onChange={d => dispatch({ type: 'onChangeDate', payload: d })} />

      <div className={classes.form}>
        <CategoryForm
          category={state.category}
          onChange={c => dispatch({ type: 'onChangeCategory', payload: c })}
        />
      </div>

      <div className={classes.form}>
        <ItemNameForm
          itemName={state.itemName}
          category={state.category}
          onChange={n => dispatch({ type: 'onChangeItemName', payload: n })}
        />
      </div>

      <div className={classes.form}>
        <PriceForm
          price={state.price}
          error={state.priceError}
          onChange={p => dispatch({ type: 'onChangePrice', payload: p })}
        />
        {state.category === 'transportation' && state.itemName === 'petrol' && (
          <PetrolForm
            priceString={state.price}
            onChangeDescription={d => dispatch({ type: 'onChangeDescription', payload: d })}
          />
        )}
      </div>

      <div className={classes.form}>
        <DescriptionForm
          description={state.description}
          onChange={d => dispatch({ type: 'onChangeDescription', payload: d })}
        />
      </div>

      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={4}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={state.isPosting}
            onClick={onSubmit}
          >
            送信
          </Button>
        </Grid>

        <Grid item xs={8}>
          {state.apiError && (
            <div style={{ height: '36px', padding: '8px 0' }}>
              <Typography variant="body2" color="error">
                {state.apiError}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <div style={{ height: '64px' }}></div>

      <FormSnackbar isOpen={state.showSuccess} message="Success!" />
    </div>
  );
};

export default Forms;
