import React from 'react';
import { Avatar, Button, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}));

const SignIn: React.FC<{
  error: string | null;
  onSignIn: () => void;
}> = ({ error, onSignIn }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography component="h1" variant="h5" color="textPrimary">
        Household Account Form
      </Typography>

      <div style={{ height: '64px' }}></div>

      <Button fullWidth variant="contained" color="primary" onClick={onSignIn}>
        Sign In with Google
      </Button>

      <div style={{ height: '16px' }}></div>

      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default SignIn;
