import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTextField } from '../StyledComponents';

const DescriptionForm: React.FC<{
  description: string;
  onChange: (description: string) => void;
}> = ({ description, onChange }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <StyledTextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="description"
          label="Description"
          name="description"
          value={description}
          type="text"
          autoComplete="off"
          onChange={e => onChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default DescriptionForm;
