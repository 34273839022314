import React from 'react';
import { Button, SwipeableDrawer } from '@material-ui/core';

const Drawer: React.FC<{
  onClickSignOut: () => void;
}> = ({ onClickSignOut }) => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const onClick = React.useCallback(() => {
    onClickSignOut();
    setIsOpenDrawer(false);
  }, [onClickSignOut, setIsOpenDrawer]);

  return (
    <SwipeableDrawer
      open={isOpenDrawer}
      onClose={() => setIsOpenDrawer(false)}
      onOpen={() => setIsOpenDrawer(true)}
    >
      <div style={{ width: '120px', padding: '12px' }}>
        <Button fullWidth variant="outlined" color="primary" onClick={onClick}>
          Sign Out
        </Button>
      </div>
    </SwipeableDrawer>
  );
};

export default Drawer;
