import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Category } from '../../types';
import { StyledTextField } from '../StyledComponents';

const suggest = (category: Category): string[] => {
  switch (category) {
    case 'food':
      return ["Sainsbury's", 'tesco', 'M&S'];
    case 'tax':
      return ['council tax'];
    case 'utility':
      return ['scottish power'];
    case 'transportation':
      return ['bus', 'petrol', 'parking'];
    case 'network':
      return ['mobile', 'broadband'];
    case 'children':
      return ['Scoosh', 'nursery'];
    case 'rent':
      return ['rent'];
    case 'travel':
      return ['hotel', 'flight', 'restaurant'];
    default:
      return [];
  }
};

const SuggestedNameButton: React.FC<{
  suggestedName: string;
  inputName: string;
  onChange: (itemName: string) => void;
}> = ({ suggestedName, inputName, onChange }) => {
  return (
    <Grid item xs={4}>
      <Button
        fullWidth
        variant={suggestedName === inputName ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => onChange(suggestedName)}
      >
        {suggestedName}
      </Button>
    </Grid>
  );
};

const ItemNameForm: React.FC<{
  itemName: string;
  category: Category | null;
  onChange: (itemName: string) => void;
}> = ({ itemName, category, onChange }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
      <Grid item xs={12}>
        <StyledTextField
          variant="outlined"
          margin="dense"
          fullWidth
          id="itemName"
          label="Item Name"
          name="itemName"
          value={itemName}
          autoComplete="off"
          onChange={e => onChange(e.target.value)}
        />
      </Grid>

      {category === null
        ? null
        : suggest(category).map(suggestedName => (
            <SuggestedNameButton
              key={suggestedName}
              inputName={itemName}
              suggestedName={suggestedName}
              onChange={onChange}
            />
          ))}
    </Grid>
  );
};

export default ItemNameForm;
