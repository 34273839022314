import React from 'react';
import { Grid } from '@material-ui/core';
import { Calendar } from '@material-ui/pickers';

const DateForm: React.FC<{
  date: Date;
  onChange: (date: Date) => void;
}> = ({ date, onChange }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Calendar
          date={date}
          onChange={date => {
            if (date instanceof Date) {
              onChange(date);
            }
          }}
          allowKeyboardControl={false}
        />
      </Grid>
    </Grid>
  );
};

export default DateForm;
