import * as firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: 'AIzaSyCKVDwstADEx-4mqDHydqdKDvIqf4YgjwI',
  authDomain: 'household-account.h-kanazawa.com',
  databaseURL: 'https://h-kanazawa-dev.firebaseio.com',
  projectId: 'h-kanazawa-dev',
  storageBucket: 'h-kanazawa-dev.appspot.com',
  messagingSenderId: '524649152208',
  appId: '1:524649152208:web:25855e8b12e0d6e99328d5',
  measurementId: 'G-SLN1XVR3Y2',
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
