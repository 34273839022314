import React from 'react';
import { StyledSuccessSnackbar } from '../StyledComponents';

const FormSnackbar: React.FC<{
  isOpen: boolean;
  message: string;
}> = ({ isOpen, message }) => {
  return (
    <StyledSuccessSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      message={<span>{message}</span>}
    />
  );
};

export default FormSnackbar;
