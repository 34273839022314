import { Form } from '../types';

export const INSERT_URL = 'https://europe-west2-h-kanazawa-dev.cloudfunctions.net/insert';

export const warmUp = async (): Promise<void> => {
  const res = await fetch(INSERT_URL, {
    method: 'GET',
    referrer: 'no-referrer',
  });

  if (res.status === 200) {
    return;
  }

  throw Error(`${res.status}: ${res.body}`);
};

export const insert = async (form: Form, idToken: string): Promise<void> => {
  const res = await fetch(INSERT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    referrer: 'no-referrer',
    body: JSON.stringify({
      ...form,
      token: idToken,
    }),
  });

  if (res.status === 200) {
    return;
  }

  throw Error(`${res.status}: ${res.body}`);
};
