import * as firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import { Container, LinearProgress } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blueGrey, deepOrange } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Drawer from './Drawer';
import Forms from './Forms';
import SignIn from './SignIn';

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

const defaultMaterialTheme = createTheme({
  palette: {
    primary: blueGrey,
    error: deepOrange,
    text: {
      primary: blueGrey[900],
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const App: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [signInError, setSignInError] = React.useState<string | null>(null);
  const [firebaseUser, setFirebaseUser] = React.useState<firebase.User | null>(null);

  const onClickSignOut = React.useCallback(() => {
    setSignInError(null);
    firebase.auth().signOut();
  }, [setSignInError]);

  const onClickSignIn = React.useCallback(async () => {
    try {
      await firebase.auth().signInWithRedirect(googleAuthProvider);
      setSignInError(null);
    } catch (e) {
      setSignInError(e instanceof Error ? e.message : 'Something wrong');
    }
  }, [setSignInError]);

  React.useEffect(() => {
    let mounted = true;
    firebase.auth().onAuthStateChanged(user => {
      if (mounted) {
        setFirebaseUser(user);
        setIsLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, [setFirebaseUser, setIsLoading]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container maxWidth="xs">
      {firebaseUser !== null ? (
        <div>
          <Drawer onClickSignOut={onClickSignOut} />
          <Forms />
        </div>
      ) : (
        <SignIn error={signInError} onSignIn={onClickSignIn} />
      )}
    </Container>
  );
};

const ThemeProvidedApp: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default ThemeProvidedApp;
