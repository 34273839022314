import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTextField } from '../StyledComponents';

const PriceForm: React.FC<{
  price: string;
  error: string | null;
  onChange: (price: string) => void;
}> = ({ price, error, onChange }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <form>
          <StyledTextField
            error={error !== null}
            variant="outlined"
            margin="dense"
            fullWidth
            id="price"
            label="Price"
            name="price"
            value={price}
            type="number"
            autoComplete="off"
            onChange={e => onChange(e.target.value)}
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default PriceForm;
