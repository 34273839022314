import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledTextField } from '../StyledComponents';

const PetrolForm: React.FC<{
  priceString: string;
  onChangeDescription: (description: string) => void;
}> = ({ priceString, onChangeDescription }) => {
  const [unitPrice, setUnitPrice] = React.useState<string>('');

  const onChange = React.useCallback(
    (newUnitPriceString: string) => {
      setUnitPrice(newUnitPriceString);
      const newUnitPrice = Number(newUnitPriceString);
      const price = Number(priceString);
      const ammount = price / newUnitPrice;
      if (![newUnitPrice, price, ammount].includes(NaN)) {
        onChangeDescription(`${newUnitPrice.toFixed(3)}£/L ${ammount.toFixed(2)}L`);
      }
    },
    [setUnitPrice, priceString, onChangeDescription]
  );

  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <form>
          <StyledTextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="£/L"
            label="£/L"
            name="£/L"
            value={unitPrice}
            type="number"
            autoComplete="off"
            onChange={e => onChange(e.target.value)}
          />
        </form>
      </Grid>
    </Grid>
  );
};

export default PetrolForm;
