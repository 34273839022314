import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Category } from '../../types';

const CategoryForm: React.FC<{
  category: Category | null;
  onChange: (category: Category) => void;
}> = ({ category, onChange }) => {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'food' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('food')}
        >
          食費
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'restaurant' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('restaurant')}
        >
          外食
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'livingware' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('livingware')}
        >
          日用品
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'social_expenses' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('social_expenses')}
        >
          社交
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'tax' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('tax')}
        >
          税金
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'rent' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('rent')}
        >
          家賃
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'utility' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('utility')}
        >
          水光熱
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'network' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('network')}
        >
          通信
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'transportation' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('transportation')}
        >
          交通
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'travel' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('travel')}
        >
          旅行
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'medical' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('medical')}
        >
          医療
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'entertainment' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('entertainment')}
        >
          娯楽
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'children' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('children')}
        >
          子供
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'others' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('others')}
        >
          その他
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'm' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('m')}
        >
          み
        </Button>
      </Grid>

      <Grid item xs={3}>
        <Button
          fullWidth
          variant={category === 'h' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onChange('h')}
        >
          ほ
        </Button>
      </Grid>
    </Grid>
  );
};

export default CategoryForm;
